var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reports-list" },
    [
      _c("div", { staticClass: "reports-toolbar" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back to Reports" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ]),
        _c("h1", [_vm._v("Protekt Reports")]),
        _c("div", [
          _vm.isAdmin
            ? _c("i", {
                directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
                staticClass: "fa clickable row-icon-button",
                class: _vm.$config.icons.general.file,
                attrs: { title: "New Report" },
                on: { click: _vm.createReport }
              })
            : _vm._e(),
          _c("i", {
            staticClass: "fa fa-th clickable",
            class: {
              "button-selected": _vm.displayMode === "grid",
              "button-deselect": _vm.displayMode !== "grid"
            },
            on: {
              click: function($event) {
                return _vm.setDisplayMode("grid")
              }
            }
          }),
          _c("i", {
            staticClass: "fa fa-th-list clickable",
            class: {
              "button-selected": _vm.displayMode === "list",
              "button-deselect": _vm.displayMode !== "list"
            },
            on: {
              click: function($event) {
                return _vm.setDisplayMode("list")
              }
            }
          })
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.displayMode === "list",
              expression: "displayMode === 'list'"
            }
          ],
          staticClass: "report-list-content"
        },
        [
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              items: _vm.reportList,
              fields: _vm.reportTableFields,
              "select-mode": "single"
            },
            on: { "row-clicked": _vm.selectReport },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex-row justify-content-start" },
                      [
                        _c("i", {
                          staticClass: "fa mr-3 report-icon",
                          class: _vm.getReportIcon(row.item),
                          style: "color: " + _vm.getReportColor(row.item) + ";"
                        }),
                        _c("span", { staticClass: "report-name" }, [
                          _vm._v(_vm._s(row.item.name))
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "cell(is_public)",
                fn: function(row) {
                  return [
                    _c("div", [
                      row.item.is_public
                        ? _c("i", {
                            staticClass: "fa fa-check-circle report-icon",
                            staticStyle: { color: "green" }
                          })
                        : _c("i", {
                            staticClass: "fa fa-times-circle report-icon",
                            staticStyle: { color: "red" }
                          })
                    ])
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(row) {
                  return [
                    _c(
                      "div",
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "icon-button",
                          class: _vm.$config.icons.stats.runReport,
                          attrs: { title: "Run Report" },
                          on: {
                            click: function($event) {
                              return _vm.runReport(row.item, $event)
                            }
                          }
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "icon-button",
                          class: _vm.$config.icons.stats.runReportOptions,
                          attrs: { title: "Run Report with Options" },
                          on: {
                            click: function($event) {
                              return _vm.selectReport(row.item, $event)
                            }
                          }
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "icon-button",
                          class: _vm.$config.icons.stats.copy,
                          attrs: { title: "Create Copy" },
                          on: {
                            click: function($event) {
                              return _vm.copyReport(row.item, $event)
                            }
                          }
                        }),
                        _vm.isAdmin
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/reports/" + row.item.id + "/edit"
                                }
                              },
                              [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass: "icon-button",
                                  class: _vm.$config.icons.general.edit,
                                  attrs: { title: "Edit Report" }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.displayMode === "grid",
              expression: "displayMode === 'grid'"
            }
          ],
          staticClass: "report-grid"
        },
        _vm._l(_vm.reportList, function(report, idx) {
          return _c(
            "div",
            {
              key: idx,
              staticClass: "report-grid-item clickable",
              on: {
                click: function($event) {
                  return _vm.selectReport(report, $event)
                }
              }
            },
            [
              _c("i", {
                staticClass: "grid-icon-options fa",
                class: _vm.$config.icons.stats.config,
                on: {
                  click: function($event) {
                    return _vm.selectReport(report, $event)
                  }
                }
              }),
              _c("i", {
                staticClass: "grid-icon-main fa",
                class: _vm.getReportIcon(report),
                style: "color: " + _vm.getReportColor(report) + ";"
              }),
              _c("div", [_vm._v(_vm._s(report.name))])
            ]
          )
        }),
        0
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "report-properties",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: "Run Report - " + _vm.selectedReportName
          }
        },
        [
          _c("ReportProperties", {
            attrs: {
              report: _vm.selectedReport,
              "modal-id": "report-properties"
            },
            on: { change: _vm.updateReportList }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "report-create-copy",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: _vm.selectedReportName
              ? "Create Copy of  - " + _vm.selectedReportName
              : "Create New Report"
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitCreateReport.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c("label", { staticClass: "green-label mt-1" }, [
                    _vm._v("New Report Name:")
                  ]),
                  _c("b-input", {
                    staticClass: "text-input",
                    attrs: { placeholder: "New Report Name", required: "" },
                    model: {
                      value: _vm.reportNewName,
                      callback: function($$v) {
                        _vm.reportNewName = $$v
                      },
                      expression: "reportNewName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "b-button",
                    { staticClass: "button", attrs: { type: "submit" } },
                    [
                      _vm._v(
                        _vm._s(_vm.selectedReport ? "Create Copy" : "Create")
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("report-create-copy")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }