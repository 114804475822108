<template>
  <div class="reports content">
    <ReportsList></ReportsList>
  </div>
</template>

<script>

import ReportsList from '@/components/stats/ReportsList'
export default {
  name: 'reports-view',
  components: {
    ReportsList
  },
  data: function () {
    return {}
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.content {
  display: flex;
  flex-direction: column;
  background-color: $default-content-background;
  overflow: hidden;
  width: 100%;
}

.reports-heading {
  color: $text-color-heading;
  font-weight: 600;
}

</style>
