<template>
  <div class="reports-list">
    <div class="reports-toolbar">
      <div>        <i
        class="icon-button"
        :class="$config.icons.general.back"
        v-b-tooltip
        title="Back to Reports"
        @click="$router.back()"
      ></i></div>

      <h1>Protekt Reports</h1>

      <div>
        <i v-if="isAdmin"
          :class="$config.icons.general.file"
          class="fa clickable row-icon-button"
           @click="createReport"
           v-b-tooltip
           title="New Report"
        ></i>
        <i class="fa fa-th clickable "
           :class="{'button-selected': displayMode==='grid', 'button-deselect': displayMode !== 'grid'}"
               @click="setDisplayMode('grid')"></i>
        <i class="fa fa-th-list clickable"
           :class="{'button-selected': displayMode==='list', 'button-deselect': displayMode !== 'list'}"
           @click="setDisplayMode('list')"></i>
      </div>

    </div>
    <div v-show="displayMode === 'list'" class="report-list-content">
      <b-table striped hover :items="reportList" :fields="reportTableFields" select-mode="single"  @row-clicked="selectReport">
        <template v-slot:cell(name)="row">
          <div class="flex-row justify-content-start">
            <i class="fa mr-3 report-icon" :class="getReportIcon(row.item)" :style="'color: ' + getReportColor(row.item) + ';'"></i>
            <span class="report-name">{{row.item.name}}</span>
          </div>
        </template>
        <template v-slot:cell(is_public)="row">
          <div>
            <i v-if="row.item.is_public" class="fa fa-check-circle report-icon" style="color: green;"></i>
            <i v-else class="fa fa-times-circle report-icon" style="color: red;"></i>
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <div>
            <i
              class="icon-button"
              :class="$config.icons.stats.runReport"
              v-b-tooltip.hover
              title="Run Report"
              @click="runReport(row.item, $event)"
            ></i>
            <i
              class="icon-button"
              :class="$config.icons.stats.runReportOptions"
              v-b-tooltip.hover
              title="Run Report with Options"
              @click="selectReport(row.item, $event)"
            ></i>
            <i
              class="icon-button"
              :class="$config.icons.stats.copy"
              v-b-tooltip.hover
              title="Create Copy"
              @click="copyReport(row.item, $event)"
            ></i>
            <router-link :to="'/reports/' + row.item.id + '/edit'" v-if="isAdmin">
              <i
                class="icon-button"
                :class="$config.icons.general.edit"
                v-b-tooltip.hover
                title="Edit Report"
              ></i>
            </router-link>
<!--            <i v-if="isAdmin || isOwner"-->
<!--              class="icon-button row-icon-button-danger"-->
<!--              :class="$config.icons.general.remove"-->
<!--              v-b-tooltip.hover-->
<!--              title="Remove/Reset"-->
<!--              v-if="!row.item.disable_delete"-->
<!--              @click="deleteWebhook(row.item)"-->
<!--            ></i>-->
          </div>
        </template>
      </b-table>
    </div>
    <div v-show="displayMode === 'grid'" class="report-grid">
      <div v-for="(report, idx) of reportList" v-bind:key="idx" class="report-grid-item clickable"
           @click="selectReport(report, $event)">
        <i class="grid-icon-options fa" :class="$config.icons.stats.config" @click="selectReport(report, $event)"></i>
        <i class="grid-icon-main fa" :class="getReportIcon(report)" :style="'color: ' + getReportColor(report) + ';'"></i>
        <div>{{report.name}}</div>
      </div>
    </div>
<!--    Report Properties Modal-->
    <b-modal id="report-properties" centered class="modal-content" size="m" hide-footer
             :title="'Run Report - ' + selectedReportName">
      <ReportProperties :report="selectedReport" modal-id="report-properties" @change="updateReportList"></ReportProperties>
    </b-modal>
<!--  Copy Report Modal -->
    <b-modal id="report-create-copy" centered class="modal-content" size="m" hide-footer
             :title="selectedReportName ? 'Create Copy of  - ' + selectedReportName : 'Create New Report'">
      <form v-on:submit.prevent="submitCreateReport">
        <div>
          <label class="green-label mt-1">New Report Name:</label>
          <b-input class="text-input" placeholder="New Report Name" v-model="reportNewName" required></b-input>
        </div>

        <div class="modal-footer">
          <b-button class="button" type="submit">{{selectedReport ? 'Create Copy': 'Create'}}</b-button>
          <b-button class="button" @click="$bvModal.hide('report-create-copy')">Cancel</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import ReportProperties from '@/components/stats/ReportProperties'
import * as config from '@/config'
import ReportHelper from '@/components/helpers/ReportHelper'

export default {
  name: 'reports-list',
  components: {
    ReportProperties
  },
  data: function () {
    return {
      displayMode: 'list',
      reportTableFieldsRaw: [
        { key: 'name',
          label: 'Report Name',
          sortable: true
        },
        { key: 'last_update',
          label: 'Last Updated',
          sortable: true,
          formatter: (value) => {
            return new Date(value).toLocaleString()
          }
        },
        { key: 'actions',
          label: 'Actions'
        },
        {
          key: 'is_public',
          label: 'Public View',
          sortable: true,
          requiresAdmin: true
        }
      ],
      reportList: [],
      selectedReport: null,
      reportNewName: '',
      isAdmin: null
    }
  },
  async mounted () {
    this.updateReportList()
    this.isAdmin = await this.$auth.roleIn(config.roles.admin)
  },
  methods: {
    async updateReportList () {
      this.reportList = await this.getReportList()
    },
    async getReportList () {
      let resp = await DataProvider.getReportList()
      if (resp.success) {
        return resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    setDisplayMode (mode) {
      this.displayMode = mode
    },
    getReportIcon (report) {
      return report.icon || 'fa fa-signal'
    },
    getReportColor (report) {
      return report.color || '#FFFFFF'
    },
    runReport(report, event) {
      if (event.stopPropagation) {
        event.stopPropagation()
      }
      this.$emit('select', report)
      this.selectedReport = report
      if (ReportHelper.requiresParameterSelection(report)) {
        this.$bvModal.show('report-properties')
      } else {
        let reportParams = ReportHelper.getReportDefaultQueryParams(report)
        this.$router.push({ path: '/reports/' + this.selectedReport.id, query: { ...reportParams } })
      }
    },
    selectReport (report, event) {
      if (event.stopPropagation) {
        event.stopPropagation()
      }
      this.$emit('select', report)
      this.selectedReport = report
      this.$bvModal.show('report-properties')
    },
    copyReport (report, event) {
      if (event.stopPropagation) {
        event.stopPropagation()
      }
      this.selectedReport = report
      this.reportNewName = this.selectedReport.name
      if (this.isReportNameTaken(this.reportNewName)) {
        let i = 1
        while (this.isReportNameTaken(this.reportNewName)) {
          this.reportNewName = `Copy of ${this.selectedReport.name}(${i})`
          i++
          console.log('Trying name: ', this.reportNewName)
        }
      }
      this.$bvModal.show('report-create-copy')
    },
    createReport () {
      this.selectedReport = null
      this.reportNewName = ''
      this.$bvModal.show('report-create-copy')
    },
    isReportNameTaken (reportName) {
      console.log(this.reportList)
      console.log(reportName)
      return this.reportList.some(report => report.name === reportName)
    },
    async submitCreateReport () {
      let resp
      if (this.selectedReport) {
        resp = await DataProvider.copyReport(this.selectedReport.id, this.reportNewName)
      } else {
        resp = await DataProvider.addReport(this.reportNewName, 0, 'fa-car')
      }
      if (resp) {
        await this.updateReportList()
        AlertHelper.successToast('Report Created Successfully', 'Report Created')
        this.$bvModal.hide('report-create-copy')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    }
  },
  computed: {
    selectedReportName () {
      if (this.selectedReport) {
        return this.selectedReport.name
      } else {
        return ''
      }
    },
    reportTableFields () {
      let filteredFields = this.reportTableFieldsRaw.filter(field => {
        if (field.requiresAdmin) {
          return this.isAdmin
        } else {
          return true
        }
      })
      console.log(filteredFields)
      return filteredFields
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.reports-list {
  display: flex;
  flex-direction: column;
  background-color: $theme-color-background-1;
  overflow: hidden;
}

.report-list-content {
  overflow: auto;
}

.reports-toolbar {
  display: flex;
  flex-direction: row;
  font-size: 2em;
  justify-content: space-between;
  align-items: center;
  color: $text-color-heading;
  font-weight: 600;
  padding: 5px;
  background-color: $theme-color-background-2;
}

.reports-toolbar * {
  margin: 5px;
}

.button-selected {
  color: $theme-color-primary-2;
}

.button-deselect {
  color: $theme-color-primary-5;
}

.report-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.report-grid * {
  margin: 15px;
}

.grid-item-options {
  font-size: 1em;
}

.report-grid-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  background: $theme-color-background-2;
  border-radius: 5px;
  border: solid 1px $theme-color-primary-3;
  padding: 10px;
  //aspect-ratio: 1;
  color: $theme-color-primary-3;
}

.grid-icon-main {
  font-size: 5em;
  //color: $theme-color-primary-2;
}

.grid-icon-options{
  font-size: 1em;
  //color: $theme-color-primary-2;
  position: absolute;
  right: -5px;
  top: -5px;
}

.grid-icon-options:hover {
  color: white;
}

.report-grid-item:hover {
  border: solid 1px $theme-color-primary-2;
  background: $theme-color-background-1;
  color: $theme-color-primary-2!important;
}

.report-icon {
  font-size: 2em;
  width: 2em;
}

.report-name {
  font-size: 1.2em;
  font-weight: 600;
}


</style>
